import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  ipAddressId: any;

  constructor(private http: HttpClient,
    public router: Router,
    private _http: Http) {
    this.getIpAddress();
  }
  get(endpoint: string, params?: any, reqOpts?: any) {
    return this.http.get(endpoint, reqOpts);
  }


  getIpAddress() {
    let ip = null;
    this.http.get('https://jsonip.com')
      .subscribe(data => {
        // console.log(data['ip']);
        this.ipAddressId = data['ip'];
        // console.log(data, 'insdie serv')
        return data;
      })
  }

  getBrowser() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return 'Firefox';
    } else if ((navigator.userAgent.indexOf("MSIE") != -1)) {
      return 'IE';
    } else {
      return 'unknown';
    }
  }

  // post(endpoint: string, body?: any, reqOpts: any = {}, auth_header: string = null) {

  //   let headers: any;
  //   let token: any;
  //   let userId: any;
  //   let ipAddress: any;
  //   let isBrowser: any;

  //   let headers = new HttpHeaders().set('Content-Type', 'application/json');
  //   reqOpts.headers = headers;
  //   return this.http.post(endpoint, body, reqOpts);
  // }

  getHeaders(headersConfig?: object): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }

  post(endpoint: string, body?: any, reqOpts: any = {}, auth_header: string = null) {

    let headers = null;
    let token = null;
    let userId = null;
    let ipAddress = null;
    let isBrowser = null;


    if (JSON.parse(sessionStorage.getItem('token'))) {
      token = JSON.parse(sessionStorage.getItem('token')).Token;
      userId = JSON.parse(sessionStorage.getItem('token')).UserID;
      isBrowser = this.getBrowser();
      let finalHeaders = new HttpHeaders().set('Content-Type', 'application/json').append('clientToken', token).append('UserId', userId).append('IPAddress', this.ipAddressId).append('Browser', isBrowser);
      // console.log(this.http.post(endpoint, body, { headers: finalHeaders }).map(res => res))
      return this.http.post(endpoint, body, { headers: finalHeaders }).map(res => res);
    } else {
      // console.log(this.http.post(endpoint, body, httpOptions).map(res => res))
      return this.http.post(endpoint, body, httpOptions).map(res => res);
    }

  }

  postImage(endpoint: string, body?: any, reqOpts: any = {}, auth_header: string = null) {
    console.log(endpoint);
    let isBrowser = this.getBrowser();
    let userId = JSON.parse(sessionStorage.getItem('token')).UserID;
    let token = JSON.parse(sessionStorage.getItem('token')).Token;
    return this._http.post(endpoint, body, {}).map(res => res.json());
  }

  convertUSDToINR() {
    return this.http.get('https://api.exchangeratesapi.io/latest?base=USD')
    // .map((res:Response) => res.json())
    // .subscribe(data => { 
    //   this.ipAddressId= data.ip; 
    //   console.log("this.ip",this.ipAddressId);
    // })
  }

  convertEURToINR() {
    return this.http.get('https://api.exchangeratesapi.io/latest?base=EUR')
  }

  checkRouteUrl(moduleID) {
    // console.log("moduleID", moduleID);
    if (JSON.parse(sessionStorage.getItem('token'))) {

      let moduleList = JSON.parse(sessionStorage.getItem('token')).ModuleLlist;
      for (let i = 0; i < moduleList.length; i++) {
        if (moduleList[i].ModuleID == moduleID) {
          if (moduleList[i].CanRead == 1) {
            // console.log("return true;");
            return true;
          } else {
            // console.log("return false;");
            return false;
          }

        }

      }
    }

  }
  uTypeId: any;
  logout() {
    debugger;

    //sessionStorage.removeItem('token');
    //this.router.navigate(['/login']);

    if (sessionStorage.getItem('UserTypeIDkey') != null) {
      this.uTypeId = sessionStorage.getItem('UserTypeIDkey')
    }
    if (this.uTypeId == "1") {
      sessionStorage.removeItem('token');
      this.router.navigate(['/login-admin']);
    }
    else if (this.uTypeId == "2") {
      sessionStorage.removeItem('token');
      this.router.navigate(['/login-cr']);
    }
    else if (this.uTypeId == "3") {
      sessionStorage.removeItem('token');
      this.router.navigate(['/login-dealer']);
    }
    else if (this.uTypeId == "4") {
      sessionStorage.removeItem('token');
      this.router.navigate(['/login-sub-admin']);
    }
    else if (this.uTypeId == "5") {
      sessionStorage.removeItem('token');
      this.router.navigate(['/login-restoration']);
    }
    else
    {
      sessionStorage.removeItem('token');
      this.router.navigate(['/login-admin']);
    }
  }
}  

import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../global/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  defaultBlock: boolean;
  contentBlock: boolean;
  noticationData = [];
  noDataFound: boolean;
  dataFound: boolean;
  userDetail: any;
  userID: any;
  userList: any = [];
  activeHeader: string = '';
  notificationCount: number = 0;
  currencyValue = 'INR';
  constructor(public router: Router, public api: ApiService) {
    if (sessionStorage.getItem('token')) {
      debugger
      this.userDetail = JSON.parse(sessionStorage.getItem('token'));
      this.userID = this.userDetail.UserID;
      // //console.log("this", this.userDetail);
    }
    if (sessionStorage.getItem('globalCurrencyType')) {
      // console.log(sessionStorage.getItem('globalCurrencyType'), "getItem");
      this.currencyValue = sessionStorage.getItem('globalCurrencyType');
    } else {
      this.currencyValue = "INR";
    }
  }
  ngOnInit() {
    this.getAllNotications();
    // hamburger 
    // $(document).ready(function () {
    //   $(document).on('click' ,'#nav-icon', function (e) {
    //     e.stopImmediatePropagation();
    //     // $('#nav-icon').removeClass('open');
    //     $(this).toggleClass('open');
    //     $('.bg-color').toggleClass('open');
    //   });
    // });

    // $(document).find('.dropdown-menu').on('click', function (e) {
    //   e.stopImmediatePropagation();
    //   console.log("sub menu click new")
    //   $('.bg-color, #nav-icon').removeClass('open'); 
    //   $('.dropdown.submenu ').removeClass('active'); 
    // })

    // $(document).on('click' ,'.bg-color.open li', function (e) {
    //   e.stopImmediatePropagation();
    //   if(!$(this).hasClass('submenu')){
    //     $('.bg-color, #nav-icon').removeClass('open');  
    //     $('.dropdown.submenu ').removeClass('active');
    //   }
    // })


    $(document).on("click", "#nav-icon", function (e) {
      e.stopImmediatePropagation();
      $(this).toggleClass('open');
      $('.bg-color').toggleClass('open');
    })

    $(document).on("click", ".closeMenu", function (e) {
      e.stopImmediatePropagation();
      $(this).removeClass('open');
      $(document).find('.bg-color, #nav-icon').removeClass('open');
    })

    $(document).on("click", ".dropdown.submenu", function (e) {
      e.stopImmediatePropagation();
      $(this).find('.dropdown-menu').slideToggle();
    });

    // mobile dropdown menu
    $(document).ready(function () {
      if ($(window).width() < 1024) {
        // $('.dropdown').click(function (e) {
        //   e.stopImmediatePropagation();
        //   console.log($(this).find('.dropdown-menu').css('display'))
        //   $(this).find('.dropdown-menu').slideToggle();
        // });
      }
    });

    $(document).ready(function () {
      $('.profile-box').click(function (e) {
        e.stopImmediatePropagation();
        if ((e.target.className !== "profile-dropdown") && (e.target.className !== "prof-name") && (e.target.className !== "box blue-btn")) {
          $('.profile-dropdown').fadeToggle();
          $('.profile-box').toggleClass('open');
        }
      });
    });

    $(document).on("click", ".notify .icon", function (e) {
      e.stopImmediatePropagation();
      $('.notify-popup').toggleClass('show');
    })
    $(document).click(function (e) {
      $('.notify').find('.notify-popup').removeClass('show');
    });
debugger
    if (sessionStorage.getItem('token')) {
      this.userDetail = JSON.parse(sessionStorage.getItem('token'));
      this.userID = this.userDetail.UserID;
      for (let i = 0; i < this.userDetail.ModuleLlist.length; i++) {
        if ((this.userDetail.ModuleLlist[i].ModuleID == '371D1989-31EF-4156-91C1-0BDB710D3AD6' ||
          this.userDetail.ModuleLlist[i].ModuleID == 'D3F280D0-77C7-4434-8CAF-2EDD2DE11ABF' ||
          this.userDetail.ModuleLlist[i].ModuleID == '1F17DA5D-9429-418F-95A8-7B8E3EE11BE2' ||
          this.userDetail.ModuleLlist[i].ModuleID == '2B333588-383E-464F-944F-D66237C31D95' ||
          this.userDetail.ModuleLlist[i].ModuleID == '53251EAE-9B7D-458D-AB4D-2118AA16F46A' ||
          this.userDetail.ModuleLlist[i].ModuleID == '21FA3F72-640B-4D75-A6EE-0EB6AD6B4A5B') && this.userDetail.ModuleLlist[i].CanRead == 1) {
          this.userList.push(this.userDetail.ModuleLlist[i]);
         
        }
      }
    }
    debugger
    if (sessionStorage.getItem('UserTypeIDkey') != null) {
      this.uTypeId = sessionStorage.getItem('UserTypeIDkey')
    }
    console.log(this.userList, "ulist");
    console.log(this.userDetail, "ulist1");
  }

  reDirectToDashBoard() {
    if (this.userDetail.UserTypeID == 1 || this.userDetail.UserTypeID == 4 || this.userDetail.UserTypeID == 5) {
      this.router.navigate(['/dashboard']);
    } else if (this.userDetail.UserTypeID == 2) {
      this.router.navigate(['/cr-dashboard']);

    } else if (this.userDetail.UserTypeID == 3) {
      this.router.navigate(['/dealer-dashboard']);
    }
  }

  reDirectToProfile() {
    if (this.userDetail.UserTypeID == 1) {
      this.router.navigate(['/admin-profile/' + this.userDetail.UserID]);
    } else if (this.userDetail.UserTypeID == 2) {
      this.router.navigate(['/cr-profile/' + this.userDetail.UserID]);

    } else if (this.userDetail.UserTypeID == 3) {
      this.router.navigate(['/dealer-profile/' + this.userDetail.UserID]);
    }
  }

  redirectToInventory() {
    if (this.userDetail.UserTypeID == 3) {
      this.router.navigate(['/dealer-inventory-list']);
    }
  }

  redirectToClientDetail() {
    if (this.userDetail.UserTypeID == 1) {
      this.router.navigate(['/client-invoice-detail']);
    }
  }
  uTypeId: any;
  logOut() {

    // this.uTypeId=sessionStorage.getItem('UserTypeIDkey');
    // sessionStorage.removeItem('token');
    // sessionStorage.removeItem('welcomeGreating');
    // this.router.navigate(['/login']);
    // localStorage.removeItem('dealerID')
    debugger;
    if (sessionStorage.getItem('UserTypeIDkey') != null) {
      this.uTypeId = sessionStorage.getItem('UserTypeIDkey')
    }

    if (this.uTypeId == "1") {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('welcomeGreating');
      this.router.navigate(['/login-admin']);
      localStorage.removeItem('dealerID')
      sessionStorage.removeItem('UserTypeIDkey');
    }
    else if (this.uTypeId == "2") {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('welcomeGreating');
      this.router.navigate(['/login-cr']);
      localStorage.removeItem('dealerID')
      sessionStorage.removeItem('UserTypeIDkey');
    }
    else if (this.uTypeId == "3") {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('welcomeGreating');
      this.router.navigate(['/login-dealer']);
      localStorage.removeItem('dealerID')
      sessionStorage.removeItem('UserTypeIDkey');
    }
    else if (this.uTypeId == "4") {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('welcomeGreating');
      this.router.navigate(['/login-sub-admin']);
      localStorage.removeItem('dealerID')
      sessionStorage.removeItem('UserTypeIDkey');
    }
    else if (this.uTypeId == "5") {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('welcomeGreating');
      this.router.navigate(['/login-restoration']);
      localStorage.removeItem('dealerID')
      sessionStorage.removeItem('UserTypeIDkey');
    }
    else {
      debugger
      this.uTypeId = sessionStorage.getItem('UserTypeIDkey');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('welcomeGreating');
      //this.router.navigate(['/login']);
      this.router.navigate(['/login-admin']);
      localStorage.removeItem('dealerID');
      sessionStorage.removeItem('UserTypeIDkey');
    }


  }
  setActiveHeader(param) {
    localStorage.removeItem('selectedDate')
    if (this.router.url !== "inventory-list") {
      $('body').removeClass('overFHidden');
    }
    localStorage.removeItem('DisabledForm')
    this.activeHeader = param;
    // console.log(param, "parammmm");
    if (param == 'dealer-inventory-list') {
      localStorage.setItem('dealerID', '3');
    } else {
      localStorage.removeItem('dealerID');
    }
  }

  reDirectToClientList(path) {
    localStorage.removeItem('ShowMeetingButton');
    localStorage.setItem('selectedDate', "");
    if (path == '/client-list') {
      this.router.navigate(['/client-list/' + 0]);
    }
    if (path) {
      localStorage.setItem('activeClientList', path);
    }
    localStorage.removeItem('ShowMeetingButton')

  }
  getAllNotications() {
    debugger
    let url = environment.AdminMasterAdminDashBoardNotifications;
    let Obj = {
      UserID: this.userDetail.UserID,
      UserTypeID: this.userDetail.UserTypeID
    }
    this.api.post(url, Obj).subscribe((res) => {
      // //console.log(res);
      if (res['Code'] == 200) {
        this.defaultBlock = false;
        this.contentBlock = true;
        if (res['Data']) {
          if (res['Data'].length > 0) {
            this.noticationData = [];
            for (let i = 0; i < res['Data'].length; i++) {
              this.noticationData = res['Data'][i]['ListOfNotifications'];
            }
            this.notificationCount = parseInt(res['totalcount']);
            // console.log(this.notificationCount, "this.this.notificationCount");
            // console.log(this.noticationData, "this.noticationData");

            // this.noticationData.splice(0, 3);
            this.noDataFound = false;
            this.dataFound = true;
          } else {
            this.noDataFound = true;
            this.dataFound = false;
          }
        }
        // console.log(this.noticationData, this.currencyValue, " this.noticationData in header")
      } else {
        this.noticationData = res['Error'];
      }
    }, (error) => {
      //console.log(error);
    });
  }
  getNotificationList() {
    this.router.navigate(['/notification-list']);

  }
  currencyConverter(val) {
    this.currencyValue = val;
    sessionStorage.setItem('globalCurrencyType', val);
    // console.log(sessionStorage.getItem('globalCurrencyType'), "getItem");
    window.location.reload();
  }
}

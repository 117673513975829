import { Injectable } from '@angular/core';
import { HttpClient } from 'node_modules/@angular/common/http';
import * as CryptoJS from 'node_modules/crypto-js';

@Injectable()
export class EncryptService {

  pass_phrase = 'MAKV2SPBNI992127';

constructor(public http: HttpClient) {
    //console.log('Hello EncryptionProvider Provider');
}

CryptoJSAesJson = {
    stringify: function (cipherParams) {
        const j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
        if (cipherParams.iv) {j['iv'] = cipherParams.iv.toString(); }
        if (cipherParams.salt) {j['s'] = cipherParams.salt.toString(); }
        return JSON.stringify(j);
    },
    parse: function (jsonStr) {
        const j = JSON.parse(jsonStr);
        const cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
        if (j.iv) {cipherParams.iv = CryptoJS.enc.Hex.parse (j.iv); }
        if (j.s) {cipherParams.salt = CryptoJS.enc.Hex.parse(j.s); }
        return cipherParams;
    }
};

decrypt(data) {
    return JSON.parse(CryptoJS.AES.decrypt(data, this.pass_phrase, {format: this.CryptoJSAesJson}).toString(CryptoJS.enc.Utf8));
}

encrypt(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.pass_phrase, {format: this.CryptoJSAesJson}).toString();
}

set(keys, value){
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
    {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  get(keys, value){
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-shared',
  templateUrl: './header-shared.component.html',
  styleUrls: ['./header-shared.component.scss']
})
export class HeaderSharedComponent implements OnInit {

  currencyValue: string = null;
  constructor() {
    if (sessionStorage.getItem('globalCurrencyType')) {
      // console.log(sessionStorage.getItem('globalCurrencyType'), "getItem");
      this.currencyValue = sessionStorage.getItem('globalCurrencyType');
    } else {
      this.currencyValue = "INR";
    }
  }

  ngOnInit() {
    if (sessionStorage.getItem('globalCurrencyType')) {
      // console.log(sessionStorage.getItem('globalCurrencyType'), "getItem");
      this.currencyValue = sessionStorage.getItem('globalCurrencyType');
    } else {
      this.currencyValue = "INR";
    }
  }
  currencyConverter(val) {
    this.currencyValue = val;
    sessionStorage.setItem('globalCurrencyType', val);
    // console.log(sessionStorage.getItem('globalCurrencyType'), "getItem");
    window.location.reload();
  }
}

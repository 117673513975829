
import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { ApiService } from '../global/api.service';

import * as CryptoJS from 'crypto-js';
@Injectable()
export class LoginService {
    EncryptedData = {
        'FormData': '',
    };
    ipAddressId:any;

    constructor(private _http: Http,private api:ApiService) { 
       this.getIpAddress();
    }

    getIpAddress(){
        this._http.get('https://jsonip.com')
        .subscribe(data => {
          this.ipAddressId = JSON.parse(data['_body']).ip;
          return data;
        })
      }
    
      getBrowserDetails(){
        if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
          return 'Opera';
        }else if(navigator.userAgent.indexOf("Chrome") != -1 ){
          return 'Chrome';
        }else if(navigator.userAgent.indexOf("Safari") != -1){
          return 'Safari';
        }else if(navigator.userAgent.indexOf("Firefox") != -1 ){
          return 'Firefox';
        }else if((navigator.userAgent.indexOf("MSIE") != -1 ) ){
          return 'IE'; 
        }else{
          return 'unknown';
        }
      }

    validateUser(user) {
        let UserData = {
            "UserTypeID":user.userType,
            "UserName": user.userName,
            "Password": user.password,
            "IPAddress": this.ipAddressId ? this.ipAddressId : null,
            "Browser" : this.getBrowserDetails()

        }
        // console.log("UserData",UserData);
        const sectkey = 'Asta7FA5Guru4404';
        const key = CryptoJS.enc.Utf8.parse(sectkey);
        const iv = CryptoJS.enc.Utf8.parse(sectkey);
        const encryptData = CryptoJS.AES.encrypt(JSON.stringify(UserData), key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString();

        this.EncryptedData['FormData'] = encryptData;


        let headers = new Headers({ 'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this._http.post(environment.LoginUrl, this.EncryptedData, options).map((res: Response) => res.json())
    }

    public isAuthenticated(): boolean {

        return this.getToken() !== null;
    }

    storeToken(token: string) {
        sessionStorage.setItem('token', token);
    }

    getToken() {
        return sessionStorage.getItem('token');
    }

    removeToken() {
        return sessionStorage.removeItem('token');
    }
    //Pradeep
    storeUserTypeToken(utypetoken: string) {
      sessionStorage.setItem('UserTypeIDkey', utypetoken);
  }
}
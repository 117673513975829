import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { HeaderSharedModule } from './components/header-shared/header-shared.module';
//For editor
// import { NgxEditorModule } from 'ngx-editor';
// font awesome
import { AngularFontAwesomeModule } from 'angular-font-awesome';
// import { TypeaheadModule } from 'ngx-bootstrap';
// import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
// for scroll

// import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
// import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// swiper

/***THAT IS USE FOR LOGIN MESSAGE AND SHOWING MESSAGES***/
import { ToastrModule } from 'ngx-toastr';
// chart
import { ChartsModule } from 'ng2-charts';
import { CdkTableModule } from '@angular/cdk/table';
// import { CommonModule } from '@angular/common';
/***THAT IS USE FOR PRELOADDER***/
import { NgxSpinnerModule } from 'ngx-spinner';
//Datatable
// import { DataTablesModule } from 'angular-datatables';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EncryptService } from './global/encrypt.service';

// select2

import {
  MatDatepickerModule,
  MatInputModule,
  MatFormFieldModule,
} from '@angular/material';

//service
import { AuthGardService } from './global/auth-gard.service';
import { LoginService } from './global/login.service';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import {DataTablesModule} from 'angular-datatables';
// import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
// import { InputsModule } from '@progress/kendo-angular-inputs';





@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    ChartsModule,
    HttpClientModule,
    NgSelectModule,
    // NgSelect2Module,
    BrowserAnimationsModule,
    // TypeaheadModule.forRoot(),
    // FormsModule,
    // CommonModule,
    CdkTableModule,
    // MatAutocompleteModule,
    // MatButtonModule,
    // MatButtonToggleModule,
    // MatCardModule,
    // MatCheckboxModule,
    // MatChipsModule,
    // MatStepperModule,
    MatDatepickerModule,
    // MatDialogModule,
    // MatExpansionModule,
    // MatGridListModule,
    // MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    // MatListModule,
    // MatMenuModule,
    // MatNativeDateModule,
    // MatPaginatorModule,
    // MatProgressBarModule,
    // MatProgressSpinnerModule,
    // MatRadioModule,
    // MatRippleModule,
    // MatSelectModule,
    // MatSidenavModule,
    // MatSliderModule,
    // MatSlideToggleModule,
    // MatSnackBarModule,
    // MatSortModule,
    // MatTableModule,
    // MatTabsModule,
    // MatToolbarModule,
    // MatTooltipModule,
    NgxSpinnerModule,
    // NgSelect2Module,
    AngularFontAwesomeModule,
    ToastrModule.forRoot({
      toastClass: 'toast toast-bootstrap-compatibility-fix',
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      tapToDismiss: true,
      maxOpened: 1,
    }),
    // NgxGalleryModule,
    HeaderSharedModule,
    DataTablesModule,
    // DateInputsModule,
    // InputsModule

  ],
  declarations: [
    AppComponent,
    HeaderComponent
   
  ],
  exports: [
    // CommonModule,
    CdkTableModule,
    // MatAutocompleteModule,
    // MatButtonModule,
    // MatButtonToggleModule,
    // MatCardModule,
    // MatCheckboxModule,
    // MatChipsModule,
    // MatStepperModule,
    MatDatepickerModule,
    // MatDialogModule,
    // MatExpansionModule,
    // MatGridListModule,
    // MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    // MatListModule,
    // MatMenuModule,
    // MatNativeDateModule,
    // MatPaginatorModule,
    // MatProgressBarModule,
    // MatProgressSpinnerModule,
    // MatRadioModule,
    // MatRippleModule,
    // MatSelectModule,
    // MatSidenavModule,
    // MatSliderModule,
    // MatSlideToggleModule,
    // MatSnackBarModule,
    // MatSortModule,
    // MatTableModule,
    // MatTabsModule,
    // MatToolbarModule,
    // MatTooltipModule,
  ],
  providers: [AuthGardService, LoginService, EncryptService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
